<template>
  <div>
    <el-checkbox-group v-model="currentVal" size="small" @change="onChange">
      <el-checkbox
        class="checkbox__item"
        v-for="(item, index) in currentAllTag"
        :label="item.tag_id"
        border
        :key="index"
      >
        {{ item.name }}
      </el-checkbox>
    </el-checkbox-group>

    <div style="margin-top: 20px">
      <el-button size="small" type="primary" @click="onTagAdd()">
        新增本馆分类
      </el-button>
    </div>

    <el-dialog
      :visible.sync="tagAddForm.visible"
      append-to-body
      title="新增本馆分类"
    >
      <el-form
        ref="tagAddFormRef"
        :model="tagAddForm.data"
        :rules="{ name: [{ required: true, message: '请填写' }] }"
        label-width="150px"
        style="width: 400px"
        @submit="onTagAddFormSubmit"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model.trim="tagAddForm.data.name" />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button @click="tagAddForm.visible = false"> 取消 </el-button>
        <el-button type="primary" @click="onTagAddFormSubmit"> 保存 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { POST } from '@/admin-shared-modules/utils/ajax'
import { defaultErrorHandler } from '@/admin-shared-modules/utils/index'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import _ from 'lodash'
import store from './book-sort-selector.store'

export default {
  data() {
    return {
      currentAllTag: this.allTag, //[{tag_id:"",name:""}]
      currentVal: this.value, //[tag_id]
      state: store.state,
      tagAddForm: {
        visible: false,
        data: {
          name: ''
        }
      }
    }
  },
  props: {
    value: { type: Array, default: () => [] }, //选中的值
    allTag: { type: Array, default: () => [] } //显示的所有分类
  },
  watch: {
    value() {
      return (this.currentVal = this.value)
    }
  },
  methods: {
    onChange(val) {
      const tags = _.reduce(
        val,
        (result, o) => {
          result.push(
            _.find(this.currentAllTag, item => {
              return item.tag_id == o
            })
          )
          return result
        },
        []
      )
      console.log(val, this.currentVal, tags)
      this.$emit('input', val)
      this.$emit('sortChange', tags)
    },
    async initCheck() {
      if (this.currentAllTag.length == 0) {
        await store.fetch()
        this.currentAllTag = this.state.items
      } else {
        // this.currentAllTag = this.allTag
      }
    },
    async onTagAddFormSubmit() {
      try {
        await this.$refs.tagAddFormRef.validate()
        const data = { name: this.tagAddForm.data.name, parent_id: 0 }
        await POST('tag', { data })
        MessageService.open({ message: '保存成功' })
        this.tagAddForm.visible = false
        this.tagAddForm.data.name = ''
        await store.fetch()
        this.currentAllTag = this.state.items
      } catch (error) {
        defaultErrorHandler(error)
      }
    },
    onTagAdd() {
      this.tagAddForm.visible = true
    }
  },
  mounted() {
    this.initCheck()
    console.log(this.currentVal)
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
::v-deep.checkbox__item {
  flex: 0 0 calc(25% - 10px);
  max-width: calc(25% - 10px);
  margin-bottom: 8px;
  margin-right: 0;
  margin-left: 10px !important;
}
</style>
