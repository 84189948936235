<template>
  <page-layout>
    <page-header :breadcrumb="['首页管理', '首页配置']" />

    <page-main>
      <div
        style="
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #eee;
          padding-bottom: 8px;
        "
      >
        <div style="color: #409eff; font-weight: bold; font-size: 16px">
          首页配置
        </div>
        <div>
          <el-button
            size="small"
            type="primary"
            @click="submit"
            :disabled="isSubmitting"
            icon="el-icon-position"
            >发布</el-button
          >
        </div>
      </div>

      <div style="display: flex; flex-direction: row">
        <div style="width: 500px">
          <div
            class="device"
            style="min-height: 620px; border: 1px solid #777; padding: 10px"
          >
            <!-- header -->
            <div
              style="display: flex; align-items: center; margin-bottom: 10px"
            >
              <div style="display: flex; align-items: center">
                <!-- <div style="margin-right: 6px">
              <i class="el-icon-user-solid" />
            </div> -->
                <div>你好，xxx</div>
              </div>
              <div style="margin-left: auto">
                {{ date }}
              </div>
            </div>

            <div
              style="
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px 10px;
              "
            >
              <template v-for="(s, i) in sections">
                <!-- banner -->
                <div
                  :key="i"
                  v-if="i === 0"
                  style="
                    height: 120px;
                    border: 1px solid #777;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    grid-column: span 2;
                    font-weight: bold;
                  "
                  :style="{
                    background: currentIndex === 0 ? '#f0f0f0' : '#fff'
                  }"
                >
                  轮播图 ({{ s.images?.length }})
                  <a
                    style="
                      position: absolute;
                      right: 8px;
                      bottom: 4px;
                      font-size: 12px;
                      cursor: pointer;
                    "
                    @click="currentIndex = 0"
                    >配置</a
                  >
                </div>

                <!-- App -->
                <div
                  :key="i + 100"
                  v-else
                  style="
                    border: 1px solid #777;
                    border-radius: 6px;
                    position: relative;
                    padding: 16px;
                    grid-column: span 2;
                    min-height: 113px;
                  "
                  :style="{
                    'grid-column': `span ${s.columns}`,
                    background: currentIndex === i ? '#f0f0f0' : '#fff'
                  }"
                >
                  <div
                    style="
                      position: absolute;
                      left: 50%;
                      top: 0;
                      transform: translate(-50%, -50%);
                      background-color: #fff;
                      padding: 2px 16px;
                      font-weight: bold;
                    "
                  >
                    {{ s.name }}
                  </div>

                  <div
                    style="display: grid"
                    :style="`
                    grid-template-columns: repeat(${
                      { 1: 3, 2: 7 }[s.columns]
                    }, 1fr);
                    gap: ${{ 1: 16, 2: 8 }[s.columns]}px
                    `"
                  >
                    <div
                      v-for="(a, i) in s.data"
                      :key="i"
                      style="
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <img :src="a.icon" style="width: 40px; height: 40px" />
                      <div style="margin-top: 8px; line-height: 1.1">
                        {{ a.alias || a.name }}
                      </div>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      right: 4px;
                      top: 0;
                      transform: translate(0, -50%);
                      background-color: #fff;
                      padding: 4px 10px;
                      font-size: 14px;
                      border-radius: 3px;
                    "
                  >
                    <!-- edit -->
                    <el-tooltip content="编辑" placement="top">
                      <a style="cursor: pointer" @click="currentIndex = i">
                        <i class="el-icon-edit"></i>
                      </a>
                    </el-tooltip>
                    <!-- remove -->
                    <el-tooltip content="删除" placement="top">
                      <a
                        style="margin-left: 0.8em; color: #e22; cursor: pointer"
                        @click="removeSection(i)"
                      >
                        <i class="el-icon-delete"></i>
                      </a>
                    </el-tooltip>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div style="margin-top: 20px; text-align: center">
            <el-button type="text" @click="() => addSection(2)"
              >添加整行模块</el-button
            >
            <el-button
              style="margin-left: 30px"
              type="text"
              @click="() => addSection()"
              >添加半行模块</el-button
            >
          </div>
        </div>

        <!-- form -->
        <div
          v-if="formData && currentIndex === 0"
          style="
            flex: 1;
            margin-left: 20px;
            border: 1px solid #777;
            min-height: 200px;
            padding: 20px;
          "
        >
          <div
            style="
              color: #409eff;
              font-weight: bold;
              font-size: 15px;
              margin-bottom: 20px;
            "
          >
            配置轮播图
          </div>

          <el-form
            :model="formData"
            label-width="80px"
            label-position="top"
            size="small"
          >
            <el-form-item label="轮播间隔(秒)">
              <el-input-number
                v-model="formData.duration"
                controls-position="right"
              />
            </el-form-item>
            <el-form-item label="图片">
              <div
                v-for="(it, i) in formData.images"
                :key="i"
                style="margin-bottom: 16px"
              >
                <img :src="it" />
                <a
                  @click="removeBannerImage(i)"
                  style="color: #f33; cursor: pointer"
                  >删除</a
                >
              </div>

              <el-upload
                action="/api/v1/admin/upload/image"
                accept="image/*"
                :show-file-list="false"
                style="
                  height: 100px;
                  border: 1px dashed #aaa;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                v-bind="uploadProps"
                v-loading="uploadProps.uploading.value"
              >
                <i class="el-icon-upload" style="font-size: 30px"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
              </el-upload>
              <div>只能上传图片文件，建议尺寸 1316*320</div>
            </el-form-item>
          </el-form>
        </div>

        <div
          v-if="formData && currentIndex > 0"
          style="
            flex: 1;
            margin-left: 20px;
            border: 1px solid #777;
            min-height: 200px;
            padding: 20px;
          "
        >
          <div
            style="
              color: #409eff;
              font-weight: bold;
              font-size: 15px;
              margin-bottom: 20px;
            "
          >
            模块配置
          </div>

          <el-form
            :model="formData"
            label-width="80px"
            label-position="top"
            size="small"
          >
            <el-form-item label="模块名称">
              <el-input v-model="formData.name" />
            </el-form-item>

            <el-form-item label="应用">
              <div
                style="
                  display: grid;
                  grid-template-columns: repeat(auto-fill, 150px);
                  gap: 16px;
                "
              >
                <div
                  v-for="(a, i) in formData.data"
                  :key="i"
                  style="
                    text-align: center;
                    padding: 10px 10px 6px;
                    position: relative;
                    border: 1px solid #eee;
                    border-radius: 4px;
                  "
                >
                  <img
                    :src="a.icon"
                    style="width: 50px; height: 50px; margin: 0 auto 8px"
                  />
                  <template v-if="appAliasEditIndex === i">
                    <div style="display: flex">
                      <el-input
                        v-model="appAliasInputValue"
                        placeholder="自定义名称"
                        autocomplete="off"
                        style="flex: 1"
                      />
                      <a
                        @click="onAliasSubmit"
                        style="margin-left: 4px; cursor: pointer"
                        >保存</a
                      >
                    </div>
                  </template>
                  <div
                    v-else
                    style="
                      margin-top: 8px;
                      height: 32px;
                      padding-top: 4px;
                      line-height: 1.2;
                    "
                  >
                    {{ a.alias || a.name }}
                    <el-tooltip content="自定义名称" placement="top">
                      <i
                        class="el-icon-edit"
                        style="
                          margin-left: 4px;
                          color: #409eff;
                          cursor: pointer;
                        "
                        @click="onEditAppAlias(i)"
                      ></i>
                    </el-tooltip>
                  </div>
                  <div
                    style="
                      position: absolute;
                      top: 4px;
                      right: 4px;
                      line-height: 1;
                    "
                  >
                    <a
                      style="color: #f33; cursor: pointer; font-size: 16px"
                      @click="removeApp(i)"
                    >
                      <i class="el-icon-delete"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div style="margin-top: 20px; text-align: right">
                <el-button type="primary" @click="appSelectDialogVisible = true"
                  >添加</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div
        style="
          text-align: right;
          margin-top: 28px;
          border-top: 1px solid #eee;
          padding-top: 12px;
        "
      ></div>
    </page-main>

    <el-dialog
      title="添加应用"
      :visible.sync="appSelectDialogVisible"
      width="800px"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="appSelectDialogVisible = false">取消</el-button>
        <el-button
          :disabled="!selected?.length"
          type="primary"
          @click="formCommit"
          >确定</el-button
        >
      </span>

      <el-tabs v-if="appSelectDialogVisible" v-model="appSelectTab">
        <el-tab-pane label="功能应用" name="1">
          <el-table
            :key="appSelectTab"
            :height="540"
            :data="buildInAppsFromLOSApps"
            @selection-change="handleSelectChange"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="checkSelectable"
            ></el-table-column>
            <el-table-column prop="icon" label="图标" width="80">
              <template v-slot="{ row }">
                <img :src="row.icon" style="width: 40px; height: 40px" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" />
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="资源应用" name="2">
          <el-table
            :key="appSelectTab"
            :height="540"
            :data="buildInAppsFromLOSResource"
            @selection-change="handleSelectChange"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="checkSelectable"
            ></el-table-column>
            <el-table-column prop="icon" label="图标" width="80">
              <template v-slot="{ row }">
                <img :src="row.icon" style="width: 40px; height: 40px" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" />
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="其他应用" name="3">
          <el-table
            :key="appSelectTab"
            :height="540"
            :data="customApps"
            @selection-change="handleSelectChange"
            v-loading="customAppsLoading"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="checkSelectable"
            />
            <el-table-column prop="icon" label="图标" width="80">
              <template v-slot="{ row }">
                <img :src="row.icon" style="width: 40px; height: 40px" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" />
            <el-table-column prop="type" label="类型">
              <template v-slot="{ row }">
                {{
                  { buildIn: '系统应用', apk: 'apk应用', web: '链接应用' }[
                    row.type
                  ]
                }}
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </page-layout>
</template>

<script lang="ts">
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { appStore } from '@/store'
import _ from 'lodash'
import moment from 'moment'
import { computed, ref, watch } from 'vue'
import { useUpload } from '../useUpload'
import { BuildInAPPs, useCustomApps } from './app'
import { useStore } from './store'

moment.locale('zh-cn')

export default {
  setup() {
    const date = ref(moment().format('YYYY年MM月DD日 dddd'))

    const store = useStore()

    //
    // Designer
    //
    const sections = computed(() => store.data.value.sections as any[])

    function removeSection(index: number) {
      store.data.value.sections.splice(index, 1)
    }

    function addSection(columns = 1) {
      store.data.value.sections.push({
        type: 'apps',
        name: '模块名称',
        columns,
        data: []
      })
    }

    //
    // From
    //

    const currentIndex = ref<number>()

    const formData = computed(() => {
      if (currentIndex.value === undefined) return
      return store.data.value.sections[currentIndex.value] as any
    })

    // banner
    const uploadProps = useUpload({
      onUpload(url) {
        formData.value.images.push(url)
      }
    })

    function removeBannerImage(index: number) {
      formData.value.images.splice(index, 1)
    }

    // app section
    function removeApp(index: number) {
      formData.value.data.splice(index, 1)
    }

    const appAliasEditIndex = ref<number>()
    const appAliasInputValue = ref('')
    function onEditAppAlias(index: number) {
      appAliasEditIndex.value = index
      const data = formData.value.data[index]
      appAliasInputValue.value = data.alias || data.name
    }
    function onAliasSubmit() {
      const value = appAliasInputValue.value.trim()
      const data = formData.value.data[appAliasEditIndex.value]
      if (data.name !== value) {
        data.alias = value
      } else {
        data.alias = ''
      }
      appAliasEditIndex.value = undefined
    }

    // app
    const appSelectDialogVisible = ref(false)
    const appSelectTab = ref('1')

    const buildInAppsFromLOSApps = computed(() => {
      const apps = _.get(appStore.state.lib.data, 'applications') as number[]
      return BuildInAPPs.filter(it => {
        if (it.type !== 'libApp') {
          return false
        }
        if (it.libAppKey && !_.includes(apps, it.libAppKey)) {
          return false
        }
        return true
      })
    })

    const buildInAppsFromLOSResource = computed(() => {
      const resource = _.get(appStore.state.lib.data, 'resource.items') as {
        id: string
      }[]
      const items = BuildInAPPs.filter(it => {
        if (it.type !== 'libResource') {
          return false
        }
        if (it.libResourceKey && !_.some(resource, { id: it.libResourceKey })) {
          return false
        }
        return true
      })
      return items.reduce((acc, it) => {
        const losRes = _.find(resource, { id: it.libResourceKey }) as any
        if (losRes.visible) {
          acc.push({
            ...it,
            name: losRes?.alias || losRes.name || it.name
          })
        }
        return acc
      }, [])
    })

    const {
      loading: customAppsLoading,
      data: customApps,
      fetch: fetchCustomApps
    } = useCustomApps()
    const selected = ref()

    watch(
      appSelectTab,
      () => {
        selected.value = []
        if (appSelectTab.value === '3') {
          fetchCustomApps()
        }
      },
      {
        immediate: true
      }
    )

    function handleSelectChange(rows) {
      selected.value = rows
    }

    function checkSelectable(row) {
      return true
    }

    function formCommit() {
      const isBuildIn = ['1', '2'].includes(appSelectTab.value)
      const count = selected.value.length + formData.value.data.length
      const max = formData.value.columns === 1 ? 3 : 7

      if (count > max) {
        MessageService.open({
          type: 'warning',
          message: `最多只能添加${max}个应用`
        })
        return
      }

      const apps = selected.value.map(it => {
        return {
          ...it
        }
      })

      formData.value.data.push(...apps)
      appSelectDialogVisible.value = false
    }

    const isSubmitting = ref(false)

    function submit() {
      isSubmitting.value = true
      store.submit(store.data.value)
      isSubmitting.value = false
      MessageService.open({
        type: 'success',
        message: '保存成功'
      })
    }

    return {
      date,
      store,
      sections,
      addSection,

      //
      currentIndex,
      formData,
      removeSection,
      removeApp,
      removeBannerImage,
      uploadProps,

      appAliasInputValue,
      appAliasEditIndex,
      onEditAppAlias,
      onAliasSubmit,

      // app
      appSelectDialogVisible,
      appSelectTab,

      buildInAppsFromLOSApps,
      buildInAppsFromLOSResource,
      customApps,
      customAppsLoading,
      handleSelectChange,
      selected,
      checkSelectable,
      formCommit,

      //
      isSubmitting,
      submit
    }
  }
}
</script>
